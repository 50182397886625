import request from '@/utils/request'

// 办事类型
export function WeGetPartyInfoPage(query) {
    return request({
        url: '/PartyInfo/WeGetPartyInfoPage',
        method: 'get',
        params: query
    })
}

//会员注册
export function WeBindRegion_LC(data) {
    return request({
        // url: 'GHCapp/WxApplyLCResident',
        url: 'Member/WeBindRegion',
        method: 'post',
        data: data
    })
}
// 会员验证
export function WeIsMember(data) {
    return request({
        url: 'Member/WeIsMember',
        method: 'post',
        data: data
    })
}